import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['modal', 'container'];

    initialize() {
        // console.log(this.modalTarget);
        // console.log("connected")
    }

    connect() {
        // console.log("connected")
    }

    launchModal(event) {
        event.preventDefault();
        this.modalTarget.classList.toggle("hidden");
    }  

    closeModal(event) {
        event.preventDefault();
        this.modalTarget.classList.toggle("hidden");
    }

    closeOnOutsideClick(event) {
        if (event.target !== this.containerTarget && !this.containerTarget.contains(event.target)) {
            this.closeModal(event)
        }
    }
}
