$(document).on("turbolinks:load", function () {
    if ($("#checkout").length > 0) {
        initStripe();  
    }
});

function initStripe() {
    let checkoutInstance = null; // Declare checkoutInstance outside to maintain scope
    const stripeKey = $("#checkout").data("stripe-key")
    const stripe = Stripe(stripeKey);
    
    // Create a Checkout Session
    $.ajax({
    url: "/create-checkout-session",
    method: "POST",
    success: async function (response) {
            try {
            
                // Initialize Embedded Checkout
                checkoutInstance = await stripe.initEmbeddedCheckout({
                    clientSecret: response.clientSecret, // Use the correct key from the response
                });

                // Mount the checkout to a DOM element
                checkoutInstance.mount("#checkout");
            } catch (error) {
                console.error("Failed to initialize checkout:", error);
            }
        },
        error: function (xhr, status, error) {
            console.error("AJAX request failed:", status, error);
        },
    });

    // Cleanup before leaving the page
    document.addEventListener("turbolinks:before-visit", function () {
        if (checkoutInstance) {
            checkoutInstance.destroy(); // Destroy the instance
            checkoutInstance = null; // Reset the reference
            console.log("Checkout instance destroyed before navigating.");
        }
    });
}

