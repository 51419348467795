// require("@rails/ujs").start();
// require("turbolinks").start();
// require("@rails/activestorage").start();

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "packs/custom"
import "packs/map"
import "packs/checkout"
import "packs/grid/main"
import "@rails/actiontext"
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { DirectUpload } from "@rails/activestorage"
import LocalTime from "local-time"


Rails.start()
Turbolinks.start()
ActiveStorage.start()
LocalTime.start()

var application = Application.start()
var context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))


require("trix")
require("@rails/actiontext")
require('channels')