// create a new controller to toggle the dropdown
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ["dropZone", "removePhotos", "fileField"]

    connect() {
        console.log("connected")
    }

    dragOverHandler(ev) {
        ev.preventDefault();
        this.dropZoneTarget.classList.add('bg-light-grey');
    }

    dropHandler(ev) {
        ev.preventDefault();
        
        toastr.success(`Uploading photos...`);
        this.dropZoneTarget.classList.remove('bg-light-grey');
        if (ev.dataTransfer.types.includes("Files")) { 
            
            // let fileInput = document.getElementById('file-upload');
            this.fileFieldTarget.files = ev.dataTransfer.files;
            // this.submitFormTarget.click();
            var form = $(this.fileFieldTarget).parents('form')[0]
            
            $(form).find("button[type='submit']")[0].click();

        } else {
            var url = ev.dataTransfer.getData("URL")
            if (url.includes("www.google.com")) {
                toastr.error('You cannot drag and drop photos from google images. Try opening the image in a new tab.')
                return false
            } 
            let array = [url]
            array.push();
            $.ajax({
                url: ev.params.url,
                type: 'POST',
                data: { photo_array: array },
                success: function () {
                    console.log("photos uploaded")        
                },
            });
            this.removePhotosTarget.classList.remove('d-none');
            this.removePhotosTarget.classList.add('d-flex');
        }
    }

    submitForm(ev) {
        $(this.fileFieldTarget).parents('form').find("button[type='submit']").first().click();
    }
}
