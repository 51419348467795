import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
static targets = [ "link", "container", "button", "paragraph" ]
  
    copy(event) {
      event.preventDefault()
      this.linkTarget.select()
      document.execCommand("copy")
      toastr.success("Link copied!")
      // $("#map_password_field").val("")
    }

    copyParagraph(event) {
      event.preventDefault()
      this.paragraphTarget.select()
      document.execCommand("copy")
      toastr.success("Paragraph copied!")
      // $("#map_password_field").val("")
    }

  toggle(event) {
    event.preventDefault()
    if (this.containerTarget.classList.value.indexOf("hidden") > -1) {
      this.containerTarget.classList.remove("hidden");
      this.buttonTarget.text("Close");
    } else {
      this.containerTarget.classList.add("hidden");
      this.buttonTarget.text("Add Password");
    }
  
  }

}